import logo from '../logo.png';
import React, { useState, useEffect } from 'react';
import './style.css';

export const CustomNav = ({ className = '' }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 120;

      setIsScrolled(scrollTop >= threshold);
    };

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`sidebar-b ${className} ${isScrolled ? 'scrolled' : ''}`}>
      <div className='d-flex'>
        <img className='image' src={logo} alt={''} />
        <div className='text-wrapper align-self-end'>Clik2pay</div>
      </div>
      <div className='content'>
        <div className='d-flex flex-column'>
          <div className='div m-0'>Telepay will meet your organization’s over-the-phone</div>
          <div className={'m-0'}>payment needs today and into the future.</div>
        </div>
      </div>
    </div>
  );
};
