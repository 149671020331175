import React from 'react';
import { useState } from 'react';
import { PrimaryButton } from './PrimaryButton';
import { TextField } from './TextField';
import { Textarea } from './TextArea';
import './style.css';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export const Telepay = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    province: '',
    country: '',
    email: '',
    phone: '',
    description: '',
    line_items: [
      { description: '', amount: '' },
    ],
  });

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...formData.line_items];
    updatedProducts[index][field] = value;

    setFormData({
      ...formData,
      line_items: updatedProducts,
    });
  };

  const submitButtonText = () => {
    return success === null ? 'SUBMIT' : 'CHECK STATUS';
  };

  const handleDeleteLineItem = (index) => {
    if (formData.line_items.length <= 1) {
      setError('1 line item is needed');
      setErrorSnackbarOpen(true);
      return;
    }

    const updatedLineItems = [...formData.line_items];
    updatedLineItems.splice(index, 1);
    setFormData({
      ...formData,
      line_items: updatedLineItems,
    });
  };

  const handleAddProduct = () => {
    setFormData({
      ...formData,
      line_items: [...formData.line_items, { description: '', amount: '' }],
    });
  };

  const handleSubmit = async (e) => {
    setSubmitted(true);
    e.preventDefault();

    if (success === null) {
      await submitInvoice();
    } else {
      await checkInvoiceStatus();
    }
  };

  const checkInvoiceStatus = async () => {
    try {
      const response = await fetch(`https://callback-click2pay.ivrnet.com/callback/invoices/${success.invoice_id}/`, {
        method: 'GET',
      });

      if (response.ok) {
        const responseData = await response.json();
        setInvoiceStatus(responseData);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'An error occurred');
        setErrorSnackbarOpen(true);
      }
    } catch (error) {
      setError('An error occurred while making the API request');
      setErrorSnackbarOpen(true);
    } finally {
      setSubmitted(false);
    }
  };

  const submitInvoice = async () => {
    try {
      const response = await fetch('https://callback-click2pay.ivrnet.com/callback/post_data/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccess(responseData);
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'An error occurred');
        setErrorSnackbarOpen(true);
      }
    } catch (error) {
      setError('An error occurred while making the API request');
      setErrorSnackbarOpen(true);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason !== 'clickaway') {
            setErrorSnackbarOpen(false);
          }
        }}
        message={error}
        action={
          <React.Fragment>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => setErrorSnackbarOpen(false)}
            >
              <CloseOutlined fontSize='small' />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity='error' sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <div className='page'>
        <div className='form'>
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='First Name'
            fieldType={'first_name'}
            value={formData.first_name}
            onChange={(e) => handleChange('first_name', e.target.value)}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Last Name'
            fieldType={'last_name'}
            value={formData.last_name}
            onChange={(e) => handleChange('last_name', e.target.value)}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Address'
            onChange={(e) => handleChange('address', e.target.value)}
            fieldType={'address'}
            value={formData.address}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='City'
            onChange={(e) => handleChange('city', e.target.value)}
            fieldType={'city'}
            value={formData.city}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Province'
            onChange={(e) => handleChange('province', e.target.value)}
            fieldType={'province'}
            value={formData.province}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Country'
            onChange={(e) => handleChange('country', e.target.value)}
            fieldType={'country'}
            value={formData.country}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Email'
            title='xxx@xxx.xx(x)'
            pattern='^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$'
            inputType='email'
            onChange={(e) => handleChange('email', e.target.value)}
            fieldType={'email'}
            value={formData.email}
          />
          <TextField
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Phone'
            inputType='phone'
            onChange={(e) => handleChange('phone', e.target.value)}
            fieldType={'phone'}
            value={formData.phone}
          />
          <Textarea
            className='design-component-instance-node'
            divClassName='design-component-instance-node-2'
            errorMessage={false}
            helperText={false}
            label='Description'
            fieldType={'description'}
            value={formData.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
          <div className='product'>
            <div className='product-section'>Product</div>
            <div className='product-list'>
              {formData.line_items.map((item, index) => (
                <div key={index} className={'product-unit-container'}>

                  <div className={`product-unit ${index > 0 ? ' mt-4' : ''}`}>
                    <div className={'w-100'} key={index}>
                      <button type='button' className='btn btn-sm btn-close float-end' aria-label='Close'
                              onClick={() => handleDeleteLineItem(index)}>
                      </button>
                      <TextField
                        className='text-field-instance'
                        divClassName='design-component-instance-node-2'
                        errorMessage={false}
                        helperText={false}
                        label={`Product Name`}
                        onChange={(e) => handleProductChange(index, 'description', e.target.value)}
                        value={item.description}
                        fieldType={`productDescription${index}`}
                      />
                      <TextField
                        className='text-field-instance mt-4'
                        divClassName='design-component-instance-node-2'
                        errorMessage={false}
                        helperText={false}
                        label={`Price`}
                        inputType={'number'}
                        value={item.amount}
                        onChange={(e) => handleProductChange(index, 'amount', e.target.value)}
                        fieldType={`productAmount${index}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <a className='add-product-link' onClick={handleAddProduct}>Add another product</a>
          </div>
          {(success || invoiceStatus) && (
            <div className='w-100'>
              <div className={'d-flex flex-column w-100 gap-8'}>
                {success && (
                  <React.Fragment>
                    <div className='alert alert-primary m-0 access-code' role='alert'>
                      <div className='col sans-pro'><strong>Access Code</strong></div>
                      <div className='col sans-code-pro'>{success.access_code} </div>
                    </div>

                    <div className='alert alert-primary m-0 access-code' role='alert'>
                      <div className='col sans-pro'><strong>Invoice Number</strong></div>
                      <div className='col sans-code-pro'>{success.invoice_id} </div>
                    </div>
                  </React.Fragment>
                )}

                {invoiceStatus && parseFloat(invoiceStatus.total_amount) === 0 && (
                  <div className='alert alert-primary m-0 access-code' role='alert'>
                    <div className='col sans-pro'><strong>Status</strong></div>
                    <div className='col sans-code-pro'>{success.callin_number} </div>
                  </div>
                )}

                {invoiceStatus && parseFloat(invoiceStatus.total_amount) !== 0 && (
                  <div className='alert alert-primary m-0 access-code' role='alert'>
                    <div className='col sans-pro'><strong>Pending Amount</strong></div>
                    <div className='col sans-code-pro'>{invoiceStatus.total_amount} </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <PrimaryButton label={submitButtonText()} disabled={submitted} />
        </div>
      </div>
    </form>
  );
};
