import PropTypes from 'prop-types';
import React from 'react';
import './style.css';

export const PrimaryButton = ({ label = 'Label', disabled = false }) => {
  return (
    <button disabled={disabled} className='primary-button' type={'submit'}>
      <div>{label}</div>
    </button>
  );
};

PrimaryButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
