import PropTypes from 'prop-types';
import React from 'react';
import './style.css';

export const Textarea = (
  {
    fieldType = '',
    required = true,
    helperText = true,
    helperText1 = 'Helper text',
    errorMessage = true,
    label = 'Label',
    errorMessage1 = 'Error message',
    inputType = 'text',
    onChange,
    value = ''
  },
) => {
  return (
    <div className='text-field'>
      <div className='label-helper-text'>
        <div className='label'>
          {required && (
            <abbr title='required' className='asterick'>*</abbr>
          )}

          <label htmlFor={fieldType}>{label}</label>
        </div>
        {helperText && (
          <div className='div-wrapper'>
            <div className='helper-text'>{helperText1}</div>
          </div>
        )}
      </div>
      <div className='input-error-message'>
          <textarea id={fieldType} value={value} className='rectangle' type={inputType} required
                    onChange={onChange} />
        {errorMessage && (
          <div className='div-wrapper'>
            <div className='error-message'>{errorMessage1}</div>
          </div>
        )}
      </div>
    </div>
  );
};

Textarea.propTypes = {
  filled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.bool,
  helperText1: PropTypes.string,
  errorMessage: PropTypes.bool,
  label: PropTypes.string,
  errorMessage1: PropTypes.string,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};
