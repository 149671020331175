import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomNav } from './component/CustomNav';
import { Telepay } from './component/Telepay';

function App() {
  return (
    <div className='App'>
      <CustomNav></CustomNav>
      <main className={'main-section'}>
        <Telepay />
      </main>
    </div>
  );
}

export default App;
